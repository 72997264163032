import React from 'react'
import styled from 'styled-components'
import Markdown from '../commons/Markdown'
import Container from '../layout/Container'
import FullScreenSection from '../layout/FullScreenSection'
import ResponsiveText from '../typography/ResponsiveText'
import theme from '../../constants/theme'

const Wrapper = styled.section`
  margin-top: 120px;
  margin-bottom: 120px;
  @media(max-width: 1024px) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .markdown {
    strong {
      font-weight: 500;
      position: relative;
      a:link, a:visited {
        text-decoration: underline;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 50%;
        background-color: ${theme.colorYellow};
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }
`

export default function SectionBigText({ anchor, content, generatedId }) {
  return (
    <Wrapper data-scroll-section data-scroll data-scroll-id={"bigTextSection_" + generatedId} id={anchor}>
      <FullScreenSection mobileAuto minHeight>
        <Container>
          <div className="self-center">
            <ResponsiveText type="medium">
              <Markdown content={content} />
            </ResponsiveText>
          </div>
        </Container>
      </FullScreenSection>
    </Wrapper>
  )
}
