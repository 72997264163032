import React from 'react'
import styled from 'styled-components'

const StyledSection = styled.div`
  /* min-height: ${props => props.minHeight ? '100vh' : 'unset'};
  height: ${props => props.minHeight ? 'auto' : '100vh'}; */

  /* ${props => props.minHeight && `
    display: flex;
    align-items: center;
  `} */

  /* @media (max-width: 1024px) {
    ${props => props.mobileAuto && `height: auto;`}
    min-height: unset;
  } */
`
export default function FullScreenSection({ mobileAuto, children, minHeight }) {
  
  return (
    <StyledSection className="w-full fullScreenSection" minHeight={minHeight} mobileAuto={mobileAuto}>
      {children}
    </StyledSection>
  )
}