import React from "react";
import styled from "styled-components";
import theme from "../../constants/theme";
import SectionServizi from "../sections/SectionServizi";
import ResponsiveText from "../typography/ResponsiveText";

const ServiziWrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 120px;

  .servizi-header {
    width: 60%;
    margin-bottom: 50px;

    p {
      text-align: left;
    }
  }

  .servizi-wrapper {
    position: relative;
  }

  .servizi-list-accordion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    list-style-type: none;

    li {
      cursor: pointer;
      height: 110px;
      max-height: 110px;
      transition: max-height 0.5s ease-in-out;
      overflow: hidden;

      @media (min-width: 1025px) {
        &:hover {
          background: ${theme.colorBlack};
          color: ${theme.colorWhite};
          /* margin: 0 -50px;
          padding: 0 50px; */
        }
      }

      .accordion-title {
        font-family: ${theme.fontSyne};
        height: 110px;
        font-weight: 700;
        font-size: 49px;
        text-transform: uppercase;
        line-height: 80px;
        letter-spacing: -2px;
        display: flex;
        align-items: center;

        svg {
          display: none;
        }
      }

      .accordion-content {
        background: ${theme.colorBlack};
        color: ${theme.colorWhite};
        display: block;
        font-family: ${theme.fontNeue};
        font-weight: 500;
        font-size: 27px;

        span {
          width: calc(100% / 12 * 8);
          padding: 0 0 25px;
          display: inline-block;
        }
      }

      &.is-open {
        background: ${theme.colorBlack};
        color: ${theme.colorWhite};
        /* margin: 0 -50px;
        padding: 0 50px; */
        height: auto;
        max-height: 300px;

        .accordion-content {
          display: block;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 60px;
    margin-top: 60px;
    .servizi-header {
      width: 100%;
      margin-bottom: 20px;

      p {
        text-align: left;
      }
    }

    .servizi-list-accordion {
      font-size: 30px;

      li {
        &:hover {
          margin: 0 -20px;
          padding: 0 20px;
        }

        .accordion-title {
          font-size: 30px;
          line-height: 49px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            display: block;
          }
        }

        .accordion-content {
          font-size: 18px;
          line-height: 26px;

          span {
            width: 100%;
          }
        }

        &.is-open {
          margin: 0 -20px;
          padding: 0 20px;
        }
      }
    }
  }
`;

export default function ServiziSection({ servizi }) {
  return (
    <ServiziWrapper
      data-scroll-section
      data-scroll-id="serviziSection"
      data-scroll
      id="servizi"
    >
      <div className="container mx-auto">
        <div className="servizi-header">
          <ResponsiveText type="extreme">
            <h2>Servizi</h2>
          </ResponsiveText>
          <p className="responsive-text-d">
          Accompagniamo la tua azienda nel percorso di innovazione, 
          tramite servizi integrati e orientati all'experience sia B2C che B2B
          </p>
        </div>
      </div>
      <SectionServizi data={servizi} />
    </ServiziWrapper>
  );
}
