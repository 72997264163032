import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/commons/Layout';
import ServiziSection from '../components/homepage/ServiziSection';
import CasiStudioSection from '../components/homepage/CasiStudioSection';
import TecnologieSection from '../components/homepage/TecnologieSection';
import ClientiSection from '../components/homepage/ClientiSection';
import ProgettiamoSection from '../components/homepage/ProgettiamoSection';
import SectionBigText from '../components/sections/SectionBigText';
import SectionHero from '../components/sections/SectionHero';

const IndexPage = ({ data, location }) => {
  const {
    backend: {
      servizis,
      tecnologies,
      clientis,
      casis,
      homePage: { hero },
      seoRules,
    },
  } = data;
  const { title, description, featuredImage, bigText } = hero;

  return (
    <Layout
      location={location}
      seo={seoRules && seoRules[0] && seoRules[0].seo}
    >
      {/* <HeroSection /> */}
      <SectionHero
        title={title}
        description={description}
        featuredImage={featuredImage}
        isHomePage
      />
      <SectionBigText content={bigText.content} />
      <CasiStudioSection casi={casis} isHomePage />
      <ClientiSection clienti={clientis} />
      <ServiziSection servizi={servizis} />
      <TecnologieSection tecnologie={tecnologies} />
      <ProgettiamoSection />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    backend {
      homePage {
        hero {
          description
          title
          featuredImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          bigText {
            content
          }
        }
      }
      servizis {
        titolo
        slug
        ordine
      }
      tecnologies(sort: "ordine:asc") {
        id
        immagine {
          url
        }
        ordine
      }
      clientis(sort: "ordine:asc") {
        titolo
        immagine {
          url
        }
        ordine
      }
      casis(where: { inSlider: true }) {
        slug
        titolo
        tags
        immagineCard {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 560, placeholder: BLURRED, quality: 100)
            }
          }
        }
        inSlider
      }
      seoRules(where: { slug_eq: "/" }) {
        slug
        seo {
          title
          description
          keywords
        }
      }
    }
  }
`;
