import React from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import arrow from '../../images/arrow-b.svg';
import { Link } from 'gatsby-plugin-react-i18next';

const AnimatedLink = styled.span`
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -2.3px;
  font-weight: 700;
  font-family: ${theme.fontSyne};
  text-transform: uppercase;
  position: relative;
  padding-right: 50px;
  overflow: hidden;
  display: inline-block;

  @keyframes onHover {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-50%);
      height: 3px;
    }

    100% {
      transform: translateX(-50%);
      height: 100%;
    }
  }

  &:before {
    content: '';
    background: url(${(props) => props.icon});
    display: inline-block;
    width: 21px;
    height: 21px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    display: inline-block;
    width: 200%;
    height: 3px;
    background: linear-gradient(
      90deg,
      ${theme.colorBlack} 50%,
      ${theme.colorYellow} 50%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &:hover {
    &:after {
      animation: onHover 1s ease-in forwards;
    }
  }
`;

export default function AlinkI18n({ children, ...props }) {
  return (
    <Link {...props}>
      <AnimatedLink icon={arrow} className="hover-cta">
        {children}
      </AnimatedLink>
    </Link>
  );
}
