import React from 'react';
import styled from 'styled-components';
import AlinkI18n from '../animations/AlinkI18n';
import Container from '../layout/Container';
import FullScreenSection from '../layout/FullScreenSection';
import ResponsiveText from '../typography/ResponsiveText';

const ProgettiamoWrapper = styled.section`
  .container {
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    margin-bottom: 120px;
  }

  .progettiamo-content {
    align-self: flex-start;
    max-width: 100%;
    margin-left: 0%;
  }

  @media (max-width: 1024px) {
    .progettiamo-content {
      margin-left: 0;
      max-width: unset;
    }
  }

  @media (max-width: 374px) {
    .progettiamo-content {
      .hover-cta {
        font-size: 30px;
      }
    }
  }
`;

export default function ProgettiamoSection() {
  return (
    <ProgettiamoWrapper
      data-scroll-section
      data-scroll
      data-scroll-id="progettiamoSection"
    >
      <FullScreenSection>
        <Container>
          <ResponsiveText type="big">
            <h2>Progettiamo insieme</h2>
          </ResponsiveText>
          <div className="progettiamo-content">
            <ResponsiveText>
              <p>
                Vuoi ideare esperienze significative e memorabili per i tuoi
                clienti?
              </p>
            </ResponsiveText>
            <p>
              <AlinkI18n to="/contatti">
                Contattaci
              </AlinkI18n>
            </p>
          </div>
        </Container>
      </FullScreenSection>
    </ProgettiamoWrapper>
  );
}
